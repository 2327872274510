var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{class:[
    'auth-layout',
    `auth-layout_${_vm.$route.name}`,
    { 'flex-column-reverse': _vm.$vuetify.breakpoint.mdAndDown },
  ],style:({
    height: _vm.$vuetify.breakpoint.mdAndDown ? undefined : `${_vm.minHeight}px`,
  }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex auth-layout__inner",attrs:{"cols":"12","lg":"7"}},[_c('div',{class:[
        'auth-layout__logo-wrap',
        'mx-auto',
        'd-flex',
        'align-center',
        {
          'justify-end': _vm.$vuetify.breakpoint.mdAndDown,
          'justify-space-between': !_vm.$vuetify.breakpoint.mdAndDown,
        },
      ]},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('router-link',{staticClass:"auth-layout__logo-link d-block",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"auth-layout__logo d-block",attrs:{"src":require("@/assets/img/logo-gray.svg")}})]):_vm._e(),_c('div',[_c('LangSwitcher',{attrs:{"dense":""}})],1)],1),_c('div',{class:[
        'ma-auto',
        'auth-layout__content',
        {
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          'px-4': !_vm.$vuetify.breakpoint.mdAndDown,
          'pt-8': _vm.$vuetify.breakpoint.mdAndDown,
          'py-10': !_vm.$vuetify.breakpoint.mdAndDown,
        },
      ]},[_c('div',{staticClass:"py-10"},[_vm._t("default")],2)])]),_c('v-col',{staticClass:"auth-layout__bg",style:({
      paddingTop: _vm.$vuetify.breakpoint.mdAndDown ? '61%' : undefined,
    }),attrs:{"cols":"12","lg":"5"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('router-link',{staticClass:"auth-layout__logo-link d-block",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"auth-layout__logo d-block",attrs:{"src":require("@/assets/img/logo-blue.svg")}})]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }